<template>
  <div>
    <miscellaneous-layout :course="'CMA New Purchase'"></miscellaneous-layout>
  </div>
</template>

<script>
import MiscellaneousLayout from "../../components/warehouse/MiscellaneousLayout.vue";

export default {
  components: {
    MiscellaneousLayout,
  },
};
</script>

<style></style>